export default function IconbRaF() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M24.5799 18.0336L16.9925 25.7376C16.4459 26.2926 15.5598 26.2926 15.0132 25.7376L5.77636 16.3588C3.40788 13.9539 3.40788 10.0548 5.77636 7.64987C8.14485 5.24497 11.9849 5.24497 14.3534 7.64987L15.995 9.31669"
                stroke="#050505"
                strokeWidth="1.5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.2427 7.76625C28.548 10.107 28.5852 13.8787 26.3542 16.2656L26.1967 16.428L24.5795 18.035L20.7845 14.1817C20.6028 13.9972 20.3085 13.9966 20.1261 14.1803L18.3776 15.9415C17.2284 17.0991 15.3737 17.0953 14.2291 15.9331L14.0725 15.7741C12.8808 14.5641 12.8531 12.6198 13.9893 11.3757L14.0902 11.2702L17.6316 7.73136C20.0244 5.34017 23.8687 5.35575 26.2427 7.76625Z"
                stroke="black"
                strokeWidth="1.5"
            />
            <path
                d="M11.7471 9.69249C10.654 8.58254 8.88165 8.58254 7.78851 9.69249C6.69536 10.8024 6.69536 12.602 7.78851 13.712"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}
